import { getOverallAnalytics } from "@/api/analytics";
import { getSection } from "@/views/files/shared/sections";
import { statuses } from "@/views/files/shared/statuses";
import { getUsers } from "@/api/users";
export default {
  name: "Home",
  titles: {
    op_tr: "Разметка оператора",
    user_tr: "Разметка пользователя",
    total: "Разметка общая"
  },
  data() {
    return {
      loading: false,
      statuses: statuses,
      users: [],
      analytics: null,
      total_files: {
        title: "Все файлы",
        count: 0
      },
      payed_files: {
        title: "Оплаченные файлы",
        count: 0
      },
      filters: {
        status: null,
        annotator: null,
        date: []
      }
    };
  },
  async mounted() {
    await this.getAnalytics();
    this.users = await getUsers();
  },
  methods: {
    async getAnalytics() {
      this.loading = true;
      let params = {
        ...this.filters
      };
      delete params.date;
      if (this.filters.date) {
        params.start_date = this.filters.date[0];
        params.stop_date = this.filters.date[1];
      }
      let data = await getOverallAnalytics(params);
      this.analytics = data.totals;
      this.total_files = {
        count: data.total_files,
        title: "Все файлы"
      };
      this.payed_files = {
        count: data.payed_files,
        title: "Оплаченные файлы"
      };
      this.loading = false;
    },
    getStatusTitle(status) {
      const section = getSection(status);
      return section.title;
    },
    getHourAndMinutes(seconds) {
      const minutes = seconds / 60;
      const hours = Math.floor(minutes / 60);
      const minutes_ = Math.round(minutes - hours * 60);
      return `${hours} час(а/ов), ${minutes_} минут`;
    }
  }
};