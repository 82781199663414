import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue";
const _hoisted_1 = {
  class: "flex items-center justify-between p-6 border-b"
};
const _hoisted_2 = {
  class: "text-3xl font-semibold"
};
const _hoisted_3 = {
  key: 0,
  class: "el-icon-loading"
};
const _hoisted_4 = {
  class: "flex w-full justify-end flex-wrap gap-2"
};
const _hoisted_5 = {
  class: "p-6"
};
const _hoisted_6 = {
  class: "summary border inline-block rounded w-full p-2 bg-gray-50 h-full text-green-400"
};
const _hoisted_7 = {
  class: "text-lg mb-3 font-semibold text-gray-400"
};
const _hoisted_8 = {
  class: "font-bold text-lg"
};
const _hoisted_9 = {
  class: "summary border inline-block rounded w-full p-2 bg-gray-50 h-full text-blue-400"
};
const _hoisted_10 = {
  class: "text-lg mb-3 font-semibold text-gray-400"
};
const _hoisted_11 = {
  class: "font-bold text-lg"
};
const _hoisted_12 = {
  class: "summary border inline-block rounded w-full p-2 bg-gray-50 h-full"
};
const _hoisted_13 = {
  class: "text-lg mb-3 font-semibold text-gray-400"
};
const _hoisted_14 = {
  class: "font-bold text-lg"
};
const _hoisted_15 = {
  class: "text-xs text-gray-400"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_el_date_picker = _resolveComponent("el-date-picker");
  const _component_el_option = _resolveComponent("el-option");
  const _component_el_select = _resolveComponent("el-select");
  const _component_el_col = _resolveComponent("el-col");
  const _component_el_row = _resolveComponent("el-row");
  return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("header", _hoisted_1, [_createElementVNode("div", null, [_createElementVNode("h1", _hoisted_2, [_cache[3] || (_cache[3] = _createTextVNode(" Аналитика ")), $data.loading ? (_openBlock(), _createElementBlock("i", _hoisted_3)) : _createCommentVNode("", true)]), _cache[4] || (_cache[4] = _createElementVNode("div", {
    class: "mt-1 text-sm font-light text-gray-700"
  }, "Сводка", -1))]), _createElementVNode("div", _hoisted_4, [_createVNode(_component_el_date_picker, {
    onChange: $options.getAnalytics,
    modelValue: $data.filters.date,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => $data.filters.date = $event),
    "value-format": "YYYY-MM-DD",
    type: "daterange",
    size: "large",
    style: {
      "max-width": "240px"
    }
  }, null, 8, ["onChange", "modelValue"]), _createVNode(_component_el_select, {
    modelValue: $data.filters.annotator,
    "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => $data.filters.annotator = $event),
    onChange: $options.getAnalytics,
    placeholder: "Выберите пользователя",
    filterable: "",
    clearable: "",
    size: "large",
    style: {
      "max-width": "240px"
    }
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.users, user => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: user._id,
        value: user._id,
        label: `${user.first_name} ${user.last_name}`
      }, null, 8, ["value", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onChange"]), _createVNode(_component_el_select, {
    modelValue: $data.filters.status,
    "onUpdate:modelValue": _cache[2] || (_cache[2] = $event => $data.filters.status = $event),
    onChange: $options.getAnalytics,
    placeholder: "Выберите статус",
    clearable: "",
    filterable: "",
    size: "large",
    style: {
      "max-width": "240px"
    }
  }, {
    default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.statuses, (val, key) => {
      return _openBlock(), _createBlock(_component_el_option, {
        key: key,
        value: key,
        label: val.title
      }, null, 8, ["value", "label"]);
    }), 128))]),
    _: 1
  }, 8, ["modelValue", "onChange"])])]), _createElementVNode("main", _hoisted_5, [_createVNode(_component_el_row, {
    gutter: 20
  }, {
    default: _withCtx(() => [_createVNode(_component_el_col, {
      span: 6,
      class: "mb-5"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_6, [_createElementVNode("div", _hoisted_7, _toDisplayString($data.total_files.title), 1), _createElementVNode("div", _hoisted_8, _toDisplayString($data.total_files.count || 0) + " файл(а/ов) ", 1)])]),
      _: 1
    }), _createVNode(_component_el_col, {
      span: 6,
      class: "mb-5"
    }, {
      default: _withCtx(() => [_createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, _toDisplayString($data.payed_files.title), 1), _createElementVNode("div", _hoisted_11, _toDisplayString($data.payed_files.count || 0) + " файл(а/ов) ", 1)])]),
      _: 1
    }), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($data.analytics, s => {
      return _openBlock(), _createBlock(_component_el_col, {
        key: s.title,
        span: 6,
        class: "mb-5"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_12, [_createElementVNode("div", _hoisted_13, _toDisplayString(s.title), 1), _createElementVNode("div", _hoisted_14, _toDisplayString($options.getHourAndMinutes(s.seconds || 0)), 1), _createElementVNode("div", _hoisted_15, _toDisplayString(s.count) + " файл(а/ов)", 1)])]),
        _: 2
      }, 1024);
    }), 128))]),
    _: 1
  })])], 64);
}