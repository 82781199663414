import { sidebarItems } from "./shared/sidebar";
import PageLayout from "@/components/PageLayout";
export default {
  name: "index",
  components: {
    PageLayout
  },
  data: () => ({
    sidebarLinks: sidebarItems
  })
};