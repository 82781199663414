import apiClient from "@/api/api-client";
export const getOverallAnalytics = async filters => {
  if (filters.status === "all") {
    delete filters.status;
  }
  const {
    data
  } = await apiClient.get("/analytics", {
    params: filters
  });
  console.log(data);
  return data;
};