import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode } from "vue";
const _hoisted_1 = {
  class: "grid grid-cols-12"
};
const _hoisted_2 = {
  class: "categories col-span-6 md:col-span-4 lg:col-span-3 xl:col-span-2 border-r"
};
const _hoisted_3 = {
  class: "p-4 font-light"
};
const _hoisted_4 = {
  class: "p-2 text-xs text-gray-400"
};
const _hoisted_5 = {
  class: "col-span-6 md:col-span-8 lg:col-span-9 xl:col-span-10"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_link = _resolveComponent("router-link");
  const _component_router_view = _resolveComponent("router-view");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("div", _hoisted_2, [_createElementVNode("ul", _hoisted_3, [_createElementVNode("li", _hoisted_4, _toDisplayString($props.title), 1), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.sidebarLinks, nl => {
    return _openBlock(), _createElementBlock("li", {
      class: "mb-2",
      key: nl.title
    }, [_createVNode(_component_router_link, {
      class: _normalizeClass(["block px-2 py-1 text-sm rounded", $options.setClass(nl.to)]),
      to: nl.to
    }, {
      default: _withCtx(() => [_createTextVNode(_toDisplayString(nl.title), 1)]),
      _: 2
    }, 1032, ["to", "class"])]);
  }), 128))])]), _createElementVNode("div", _hoisted_5, [_createVNode(_component_router_view)])]);
}