export default {
  name: "index",
  props: {
    sidebarLinks: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  },
  computed: {
    currentLocation() {
      return this.$route;
    }
  },
  methods: {
    setClass(to) {
      const resolved = this.$router.resolve(to);
      if (this.currentLocation.path.includes(resolved.path)) {
        return "router-link-active";
      }
    }
  }
};